import { useEffect } from 'react';
import ProtectedRoute from 'components/layout/ProtectedRoute';
import { useMessage } from 'utils/MessageContext';
import Login from 'components/pages/Login';
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { fetcher } from 'utils/Fetch';
import AddCollectionImages from 'components/Collections/Modify/Images/AddImages';

// On route changes
const usePageChange = () => {
	const location = useLocation();
	const { setMessage } = useMessage();

	// Initialize GA
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
	}, []);

	// Clear message bar
	useEffect(() => {
		setMessage(null);
	}, [location, setMessage]);
};

export default function App() {
	// Hooks
	usePageChange();
	const { message, setMessage } = useMessage();

	// Effects
	useEffect(() => {
		const timeout = setTimeout(() => setMessage(null), 5000);
		return () => clearTimeout(timeout);
	}, [message, setMessage]);

	return (
		<SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false, fetcher: url => fetcher(url) }}>
			<Routes>
				<Route path='public'>
					<Route path='collections/images/:site_id/:collection_name/' element={<AddCollectionImages />} />
				</Route>
				<Route path='/login' element={<Login />} />
				<Route path='*' element={<ProtectedRoute />} />
			</Routes>
		</SWRConfig>
	);
}
