import styled from 'styled-components';
import { useCSVReader } from 'react-papaparse';
import { Button, Row, Col } from 'react-bootstrap';
import { tidyFileSize } from 'utils/Tidy';

import { useMessage } from 'utils/MessageContext';
import { UploadZone, Panel } from 'components/Collections/Components';

const Icon = styled.i`
	font-size: 3rem;
`;

const Details = styled.div`
	display: flex;
	gap: 1rem;
`;

const ProgressStyle = {
	marginTop: '1.5rem',
	background: 'var(--bs-primary)',
	boxShadow: 'none'
};

const Import = ({ importedCsv, setImportedCsv }) => {
	// Hooks
	const { CSVReader } = useCSVReader();
	const { setMessage } = useMessage();

	// Handlers
	const handleFileUpload = data => {
		if (data.length === 0) {
			setMessage({ variant: 'danger', text: `File upload error - No data found.` });
		} else if (data.length > 100) {
			setMessage({ variant: 'danger', text: `File upload error - Too many rows. You can only upload up to 100 rows at a time.` });
		} else {
			setTimeout(() => setImportedCsv(data), 1000);
		}
	};

	const handleError = error => {
		setMessage({ variant: 'danger', text: `CSV parse error - ${error}.` });
		setImportedCsv([]);
	};

	// Config
	const csvConfig = {
		header: true,
		transform: value => value.replaceAll('\n', '<br />'),
		error: error => handleError(error)
	};

	return (
		<Row>
			<Col lg={6}>
				<Panel className='mb-3'>
					<h5>
						<i className='fas fa-info-circle text-warning me-3'></i>Instructions
					</h5>
					<ol className='mb-0'>
						<li>Open the csv file and delete all empty columns and rows through to the end of the file.</li>
						<li>A correctly formatted csv even with hundreds of rows & columns, should be no larger than 1 MB.</li>
						<li>Upload the customer's complete CSV file using the button to the right.</li>
					</ol>
				</Panel>
			</Col>
			<Col>
				<CSVReader onUploadAccepted={({ data }) => handleFileUpload(data)} config={csvConfig}>
					{({ getRootProps, acceptedFile, getRemoveFileProps, ProgressBar }) => (
						<UploadZone>
							{!acceptedFile || importedCsv.length === 0 ? (
								<div className='text-center'>
									<h6 className='mb-3'>Upload the complete CSV file below:</h6>
									<Button variant='dark' {...getRootProps()}>
										Upload File <i className='fas fa-upload'></i>
									</Button>
								</div>
							) : (
								<div>
									<Details>
										<Icon className='fas fa-file-csv text-muted' />
										<div>
											<strong>{acceptedFile.name}</strong>
											<div>{tidyFileSize(acceptedFile.size)}</div>
										</div>
									</Details>
									<Button variant='danger' {...getRemoveFileProps()} className='d-block mt-4 mx-auto'>
										Remove file <i className='fas fa-trash-alt'></i>
									</Button>
									<ProgressBar style={ProgressStyle} />
								</div>
							)}
						</UploadZone>
					)}
				</CSVReader>
			</Col>
		</Row>
	);
};

export default Import;
